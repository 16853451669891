import React from "react"
import Header from '../components/header'
import { Container, Row, Col } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import { withPrefix } from "gatsby";

export default function Principles() {
  return (
    <>
    
    <Header activePage="Principles" />
    <Container className="fluid">
    <h1 style={{paddingTop: "15px"}}>Principles</h1>
      <Row>
        <Col>
         
        </Col>
      </Row>
    </Container>
    </>
  )}